import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Section3 = () => {
    return (
        <>
            <div className="footer-sec3">
                <Container>
                    <h4 className='text-darkbrown fw-medium fs-4 pt-3 text-start'>
                       Locate Us
                    </h4>
                    <div className='pt-3'>
                        {/* <div className='pt-3'>
                            <Link to="/about" className='text-decoration-none text-darkbrown fs-5'>
                                Our Products
                            </Link>
                        </div>
                        <div className='pt-3'>
                            <Link to="/about" className='text-decoration-none text-darkbrown fs-5'>
                                About Us
                            </Link>
                        </div>
                        <div className='pt-3'>
                            <Link to="/about" className='text-decoration-none text-darkbrown fs-5'>
                                Contact us
                            </Link>
                        </div> */}
                        <span>
                            Google Maps Location Updating Soon!!!!!
                        </span>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Section3