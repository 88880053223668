import React, { useState } from 'react'
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import categoryimage from '../../assests/images/category1.png'
import axios from 'axios'
import { Helmet } from 'react-helmet'

const HomeProducts = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
    });


    const handleShowModal = (category) => {
        setSelectedCategory(category);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedCategory('');
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            ...formData,
            category: selectedCategory, // Include selected category in the form submission
        };
        const baseUrl = process.env.REACT_APP_API_URI;
        try {
            const response = await axios.post(`${baseUrl}emails/send-category-email`, payload);
            console.log('Email sent successfully:', response.data);
            alert('Thank you! We will get back to you soon.');
            setFormData({ name: '', email: '', phone: '' }); // Clear form
            handleCloseModal(); // Close modal
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Failed to submit the form. Please try again.');
        }
    };
    return (
        <>
            <Helmet>
                <title>Home Comforts | High-Quality Cushions, Pillows, Mosquito Nets, and More</title>
                <meta
                    name="description"
                    content="Discover our exclusive range of home comfort products, including cushion covers, pillows, and mosquito nets. Crafted for style and comfort, perfect for every household. Shop now!"
                />
            </Helmet>
            <div className="home-products">
                <Container>
                    {/* Heading Section */}
                    <Row className="align-items-center">
                        <Col xs={12} md={12} className="pt-4 text-center">
                            <h1 className="text-darkbrown fs-2">Home Comforts</h1>
                            {/* <p className="text-darkbrown fw-normal fs-5">
                                Browse our curated collections for every style.
                            </p> */}
                        </Col>
                    </Row>

                    {/* Categories Section */}
                    <Row className="mt-4">
                        <Col xs={12} md={4} className="mb-4">
                            <Link className='text-decoration-none' onClick={() => handleShowModal('Cushion Cover')}>
                                <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1732802619/products/de6gp5xxrb5is44o5red.jpg" alt="" className="img-fluid product-img" />
                                <div>
                                    <h2 className="text-darkbrown fs-5 pt-3">Cushion Cover</h2>
                                </div>
                                <hr />
                            </Link>
                        </Col>
                        <Col xs={12} md={4} className="mb-4">
                            <Link className='text-decoration-none' onClick={() => handleShowModal('Pillows')}>
                                <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1732802618/products/dculvb8oqgkgt5ir27xq.jpg" alt="" className="img-fluid product-img" />
                                <div>
                                    <h2 className="text-darkbrown fs-5 pt-3">Pillows</h2>
                                </div>
                                <hr />
                            </Link>
                        </Col>
                        <Col xs={12} md={4} className="mb-4">
                            <Link className='text-decoration-none' onClick={() => handleShowModal('Mosquito Nets')}>
                                <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733077022/products/hw2f1b1jufgithlsrvkl.webp" alt="" className="img-fluid product-img" />
                                <div>
                                    <h2 className="text-darkbrown fs-5 pt-3">Mosquito Nets</h2>
                                </div>
                                <hr />
                            </Link>
                        </Col>
                    </Row>
                </Container>
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedCategory}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <p>Details about {selectedCategory} go here.</p> */}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="formEmail">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter your email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="formPhone">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                    type="tel"
                                    placeholder="Enter your phone number"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Button type="submit" className="mt-3 btn-darkbrown border-0">
                                Submit
                            </Button>
                        </Form>

                    </Modal.Body>
                    {/* <Modal.Footer> */}
                    {/* <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button> */}
                    {/* </Modal.Footer> */}
                </Modal>
            </div>
        </>
    )
}

export default HomeProducts