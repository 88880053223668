import React from "react";
import { Card, Row, Col, Image } from "react-bootstrap";
import { FaStar } from "react-icons/fa";

const TestimonialCard = ({ name, review }) => {
  return (
    <Card className="p-3 border-0 shadow-sm">
      <Card.Body>
        <div className="d-flex mb-3">
          {[...Array(5)].map((_, i) => (
            <FaStar key={i} color="#FFD700" />
          ))}
        </div>
        <Card.Text className="mb-4">
          <span>
            {review}
          </span>
        </Card.Text>
        <Row className="align-items-center">
          {/* <Col xs="auto">
            <Image
              src="https://via.placeholder.com/50"
              roundedCircle
              width="50"
              height="50"
            />
          </Col> */}
          <Col>
            <Card.Text className="mb-0 fw-bold"><span>
              {name}</span></Card.Text>
            {/* <Card.Text className="text-muted" style={{ fontSize: "0.9rem" }}>
              {designation}
            </Card.Text> */}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default TestimonialCard;
