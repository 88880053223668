import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import TestimonialCard from "../TestimonialCard";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Container } from "react-bootstrap";

const TestimonialSection = () => {
    const testimonials = [
        { id: 1, name: "Sanya Mehta", review: "I recently bought the Premium Curtains for my living room, and they’ve completely transformed the space. The fabric is luxurious, and the colors are vibrant. The best part is the smooth mechanism, making them so easy to use. Highly recommend!" },
        { id: 2, name: "Rohit Verma", review: "The Standard Curtains are perfect for my bedroom. They fit well, look elegant, and are very affordable. They give my room a fresh new vibe. Great quality at a budget price." },
        { id: 3, name: "Anjali Patel", review: "These bedsheets are so soft and comfortable, perfect for a good night’s sleep. The design is beautiful and adds a touch of class to my bed." },
        { id: 4, name: "Vikram Singh", review: "The Sofa Cover I bought was a perfect fit for my couch. It protects the sofa and looks stylish too. The material is high quality and very durable." },
        { id: 5, name: "Priya Sharma", review: "he carpet is of exceptional quality. It feels great underfoot and gives the living room a cozy and inviting atmosphere. It was easy to clean as well." },
        { id: 6, name: "Manoj Kumar", review: "These mats are ideal for the kitchen. They’re non-slip and add a pleasant look to the space. Very happy with the quality!" },
        { id: 7, name: "Neha Gupta", review: "Finally, a mattress that gives me proper support! I’ve been sleeping better since I bought this. It’s neither too soft nor too firm—just perfect." },
        { id: 8, name: "Ashwin Desai", review: "The blankets are soft, warm, and cozy. They’ve been perfect for the chilly weather. Highly recommend them for anyone looking for comfort and warmth." },
        { id: 9, name: "Ayesha Khan", review: "The sofa pillows are extremely comfortable. They offer the right amount of support and are great for lounging. The fabric is smooth and looks elegant on my couch." },
        { id: 10, name: "Arun Sharma", review: "These bed pillows have been a game-changer for me. They provide perfect neck support and are very soft. I’ve had the best sleep ever!" },
        { id: 11, name: "Priya Joshi", review: "I purchased the mosquito nets for my home, and they’re very effective in keeping insects out. It’s made of high-quality material and is easy to install" },
        { id: 12, name: "Rajesh Kumar", review: "The artificial grass looks so real! It’s perfect for our balcony, and it’s easy to clean. It gives our outdoor space a fresh, green touch without any maintenance." },
        { id: 13, name: "Madhuri Reddy", review: "I bought the raincoat for my morning commute, and it’s perfect. It’s lightweight, durable, and keeps me dry even during the heaviest showers." },
        { id: 14, name: "Gaurav Mehta", review: "The tarpaulin is super strong and has been so useful for protecting our outdoor furniture. It's a great investment, especially during the monsoon." },

    ];

    return (
        <div className="testimonial-section bg-lightestblue p-5">
            <Container className="d-flex flex-column justify-content-center align-items-center">
                <h4 className="text-darkbrown fs-2 text-center">
                    See What Others Have To Say
                </h4>
                {/* <p className="text-darkbrown fw-normal text-center responsive-paragraph">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris sed est elit. Vivamus
                    lacinia semper neque, eget feugiat ipsum tristique lobortis.
                </p> */}
            </Container>
            <Swiper
                spaceBetween={30}
                slidesPerView={3} // Default for large screens
                pagination={{ clickable: true }}
                navigation={true}
                autoplay={{
                    delay: 3000, // Delay between slides in milliseconds (3 seconds)
                    disableOnInteraction: false, // Autoplay continues even after user interacts
                }}
                loop={true} // Enable looping of slides
                modules={[Pagination, Navigation, Autoplay]} // Add Autoplay to the modules
                className="mySwiper"
                breakpoints={{
                    320: { slidesPerView: 1, spaceBetween: 10 }, // Extra small screens
                    640: { slidesPerView: 1, spaceBetween: 10 }, // Small screens
                    768: { slidesPerView: 2, spaceBetween: 20 }, // Medium screens
                    1024: { slidesPerView: 3, spaceBetween: 30 }, // Large screens
                }}
            >
                {testimonials.map((testimonial) => (
                    <SwiperSlide key={testimonial.id}>
                        <TestimonialCard {...testimonial} />
                    </SwiperSlide>
                ))}
            </Swiper>

        </div>
    );
};

export default TestimonialSection;
