import React from 'react'
import { Container } from 'react-bootstrap'
import { FaFacebookF } from 'react-icons/fa'
import { IoLogoInstagram } from 'react-icons/io'

const Section1 = () => {
    return (
        <>
            <div className="footer-sec1">
                <Container>
                    <img src="logo.png" alt="" />
                    <p className="text-darkbrown fw-normal fs-6  text-start pt-3">
                        At Fakhri Traders, we pride ourselves on offering a wide selection of handloom items that meet the highest standards of quality and affordability
                    </p>
                    <div>
                        <h5 className='text-darkbrown fw-medium fs-2'>
                            Follow Us
                        </h5>
                        <div>
                            <a href="https://www.instagram.com/fakhrii.traders/" className='text-dark'> <IoLogoInstagram size={'50px'} className=' p-3 rounded-circle border-brown me-3' /></a>
                            {/* <FaFacebookF size={'50px'} className=' p-3 rounded-circle border-brown' /> */}
                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Section1