import React from 'react'
import TopBar from '../components/TopBar'
import Header from '../components/Header'
import NotificationBar from '../components/NotificationBar'

const HeaderLayout = () => {
  return (
    <>
    {/* <NotificationBar /> */}
    <TopBar />
    {/* <NotificationBar /> */}
    <Header />
    </>
  )
}

export default HeaderLayout