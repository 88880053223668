import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import whyvideo from '../../assests/videos/video.mp4';

const WhyChooseUs = () => {
    return (
        <>
            <div className="why-choose-us-section bg-lightestblue">
                <Container className="py-5">
                    <Row className="align-items-center">
                        {/* Text Section */}
                        <Col xs={12} md={6} lg={4} className="mb-4 mb-lg-0">
                            <h2 className="fw-medium fs-2 mb-3">Why Choose Us?</h2>
                            <p className="text-secondary">
                                At Fakhri Traders, we stand out for our commitment to quality, offering an extensive range of premium handloom and home furnishing products that cater to all your needs. Our diverse selection, from curtains and upholstery to roller blinds, mattresses, and artificial grass, ensures that you’ll find the perfect solutions for your home or business. We combine top-notch products with competitive pricing, providing excellent value whether you’re purchasing in bulk or for personal use.
                            </p>
                        </Col>
                        {/* Video Section */}
                        <Col xs={12} md={6} lg={8}>
                            <video
                                src={whyvideo}
                                className="w-100"
                                autoPlay
                                muted
                                loop
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default WhyChooseUs;
