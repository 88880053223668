import React from 'react'
import Subscribe from './Footer/Subscribe'
import { Col, Container, Row } from 'react-bootstrap'
import Section1 from './Footer/Section1'
import Section2 from './Footer/Section2'
import Section3 from './Footer/Section3'
import Section4 from './Footer/Section4'
import Copyright from './Footer/Copyright'

const Footer = () => {
    return (
        <>
            <footer className='bg-white'>
                {/* <Subscribe /> */}
                <Container className='p-3'>
                    <Row className='justify-content-between'>
                        <Col lg={4}>
                            <Section1 />
                        </Col>
                        {/* <Col lg={3}>
                            <Section2 />
                        </Col> */}
                        <Col lg={3}>
                            <Section3 />
                        </Col>
                        <Col lg={4}>
                            <Section4 />
                        </Col>
                    </Row>
                </Container>
                <Copyright />
            </footer>
        </>
    )
}

export default Footer