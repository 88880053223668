import React from 'react'
import Hero from '../components/Homepage/Hero'
import Categorysection from '../components/Homepage/Categorysection'
import Aboutussection from '../components/Homepage/Aboutussection'
import WhyChooseUs from '../components/Homepage/WhyChooseUs'
import OurProducts from '../components/Homepage/OurProducts'
import TestimonailSection from '../components/Homepage/TestimonailSection'
import Projects from '../components/Homepage/Projects'

const Home = () => {
  return (
    <>
      <Hero />
      <Categorysection />
      <Aboutussection />
      <WhyChooseUs />
      <Projects />
      {/* <OurProducts /> */}
      <TestimonailSection />
    </>
  )
}

export default Home