import React from 'react';
import { Container } from 'react-bootstrap';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

const Projects = () => {
    const images = [
        'https://res.cloudinary.com/dj6r4vyd8/image/upload/v1732781474/projects/yhiq32fngueix9tcxxbl.jpg',
        'https://res.cloudinary.com/dj6r4vyd8/image/upload/v1732781474/projects/xrae6wm6fc3qioiqgmg5.jpg',
        'https://res.cloudinary.com/dj6r4vyd8/image/upload/v1732781474/projects/fjiikc0c8mvn9m6iwyo0.jpg',
        // 'https://res.cloudinary.com/dj6r4vyd8/image/upload/v1732781473/projects/wgtofnvf8z01thzdsa1l.jpg',
        'https://res.cloudinary.com/dj6r4vyd8/image/upload/v1732781472/projects/ka1cykxqpgxirghvmsut.jpg',
        'https://res.cloudinary.com/dj6r4vyd8/image/upload/v1732800735/projects/new/ysyzdyum6ghbgnp6vq7u.jpg',
        'https://res.cloudinary.com/dj6r4vyd8/image/upload/v1732800732/projects/new/qtb75ljcqd7sjxiaobre.jpg',
        'https://res.cloudinary.com/dj6r4vyd8/image/upload/v1732800732/projects/new/wfyyz5dzw86i0tqdhnyq.jpg',
        'https://res.cloudinary.com/dj6r4vyd8/image/upload/v1732800732/projects/new/aqgvwyw7niopjfzkwbai.jpg',
    ];

    return (
        <div className="projects py-5">
            <Container>
                <div className='text-center '>
                    <h3 className='display-5 fs-2 fw-normal'>
                        Our Most Recent Projects
                    </h3>
                </div>
                <ResponsiveMasonry
                    columnsCountBreakPoints={{
                        350: 1, // 1 column for widths up to 350px
                        750: 2, // 2 columns for widths up to 750px
                        1024: 3, // 3 columns for widths up to 1024px
                    }}
                >
                    <Masonry gutter="15px">
                        {images.map((src, index) => (
                            <img
                                key={index}
                                src={src}
                                alt={`Project ${index + 1}`}
                                style={{
                                    width: '100%',
                                    display: 'block',
                                    borderRadius: '8px', // Optional: Rounded corners
                                }}
                            />
                        ))}
                    </Masonry>
                </ResponsiveMasonry>
            </Container>
        </div>
    );
};

export default Projects;
