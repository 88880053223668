import React, { useState } from 'react'
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import categoryimage from '../assests/images/category1.png'


const Products = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    });


    const handleShowModal = (category) => {
        setSelectedCategory(category);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedCategory('');
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Form submitted:', formData);
        // Add your form submission logic here (e.g., send data to a server)
        setFormData({ name: '', email: '', message: '' }); // Clear form after submission
        handleCloseModal(); // Close modal after submitting
    };
    return (
        <>
            <div className="products">
                <Container fluid>
                    {/* Heading Section */}
                    <Row className="align-items-center">
                        <Col xs={12} md={12} className="pt-4 text-center">
                            <h4 className="text-darkbrown fs-2 ">Home Furnishing Products</h4>
                            {/* <p className="text-darkbrown fw-normal fs-5">
                                Browse our curated collections for every style.
                            </p> */}
                        </Col>
                    </Row>

                    {/* Categories Section */}
                    <Container>
                        <Row className="mt-4">
                            <Col xs={12} md={3} className="mb-4">
                                <Link className="text-decoration-none" onClick={() => handleShowModal('Premium Curtains')}>
                                    <img src={categoryimage} alt="Premium Curtains" className="img-fluid product-img" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Premium Curtains</h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                            <Col xs={12} md={3} className="mb-4">
                                <Link className="text-decoration-none" onClick={() => handleShowModal('Standard Curtains')}>
                                    <img src={categoryimage} alt="Standard Curtains" className="img-fluid product-img" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Standard Curtains</h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                            <Col xs={12} md={3} className="mb-4">
                                <Link className="text-decoration-none" onClick={() => handleShowModal('Bedsheet')}>
                                    <img src={categoryimage} alt="Bedsheet" className="img-fluid product-img" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Bedsheet</h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                            <Col xs={12} md={3} className="mb-4">
                                <Link className="text-decoration-none" onClick={() => handleShowModal('Sofa Cover')}>
                                    <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1732803418/products/khzpendrug25nkvmjdbm.jpg" alt="Sofa Cover" className="img-fluid product-img" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Sofa Cover</h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xs={12} md={3} className="mb-4">
                                <Link className='text-decoration-none' onClick={() => handleShowModal('Carpets')}>
                                    <img src={categoryimage} alt="" className="img-fluid product-img" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Carpets</h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                            <Col xs={12} md={3} className="mb-4">
                                <Link className='text-decoration-none' onClick={() => handleShowModal('Carpets')}>
                                    <img src={categoryimage} alt="" className="img-fluid product-img" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Mats</h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                            <Col xs={12} md={3} className="mb-4">
                                <Link className='text-decoration-none' onClick={() => handleShowModal('Bed Matress')}>
                                    <img src={categoryimage} alt="" className="img-fluid product-img" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Bed Mattress</h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                            <Col xs={12} md={3} className="mb-4">
                                <Link className='text-decoration-none' onClick={() => handleShowModal('Bankets')}>
                                    <img src={categoryimage} alt="" className="img-fluid product-img" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Blankets</h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        {/* Heading Section */}
                        <Row className="align-items-center">
                            <Col xs={12} md={12} className="pt-4 text-center">
                                <h4 className="text-darkbrown fs-2">Home Comforts</h4>
                                {/* <p className="text-darkbrown fw-normal fs-5">
                                Browse our curated collections for every style.
                            </p> */}
                            </Col>
                        </Row>

                        {/* Categories Section */}
                        <Row className="mt-4">
                            <Col xs={12} md={4} className="mb-4">
                                <Link className='text-decoration-none' onClick={() => handleShowModal('Cushion Cover')}>
                                    <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1732802619/products/de6gp5xxrb5is44o5red.jpg" alt="" className="img-fluid product-img" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Cushion Cover</h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <Link className='text-decoration-none' onClick={() => handleShowModal('Pillows')}>
                                    <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1732802618/products/dculvb8oqgkgt5ir27xq.jpg" alt="" className="img-fluid product-img" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Pillows</h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <Link className='text-decoration-none' onClick={() => handleShowModal('Mosquito Nets')}>
                                    <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733077022/products/hw2f1b1jufgithlsrvkl.webp" alt="" className="img-fluid product-img" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Mosquito Nets</h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        {/* Heading Section */}
                        <Row className="align-items-center">
                            <Col xs={12} md={12} className="pt-4 text-center">
                                <h4 className="text-darkbrown fs-2">Outdoor and Utility Products</h4>
                                {/* <p className="text-darkbrown fw-normal fs-5">
                                Browse our curated collections for every style.
                            </p> */}
                            </Col>
                        </Row>

                        {/* Categories Section */}
                        <Row className="mt-4">
                            <Col xs={12} md={4} className="mb-4">
                                <Link className='text-decoration-none' onClick={() => handleShowModal('Artificial Green Grass')}>
                                    <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733076363/products/hqx0287acmt1urwc0yyw.webp" alt="" className="img-fluid product-img" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Artificial Green Grass</h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <Link className='text-decoration-none' onClick={() => handleShowModal('Raincoats')}>
                                    <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733076627/products/opu37wgkekrpqmnne0tc.webp" alt="" className="img-fluid product-img" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Raincoats</h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                            <Col xs={12} md={4} className="mb-4">
                                <Link className='text-decoration-none' onClick={() => handleShowModal('Tarpaulin (Tadpatri)')}>
                                    <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733076838/products/fvzy6jivxusoxvx3rhep.jpg" alt="" className="img-fluid product-img" />
                                    <div>
                                        <h5 className="text-darkbrown fs-5 pt-3">Tarpaulin (Tadpatri)</h5>
                                    </div>
                                    <hr />
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{selectedCategory}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* <p>Details about {selectedCategory} go here.</p> */}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter your name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="formEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter your email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Form.Group controlId="formMessage">
                                <Form.Label>Message</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    placeholder="Enter your message"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>

                            <Button type="submit" className="mt-3 btn-darkbrown border-0">
                                Submit
                            </Button>
                        </Form>
                    </Modal.Body>
                    {/* <Modal.Footer> */}
                    {/* <Button variant="secondary" onClick={handleCloseModal}>
                            Close
                        </Button> */}
                    {/* </Modal.Footer> */}
                </Modal>
            </div>
        </>
    )
}

export default Products