import React from 'react'
import { Badge, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import categoryimage from '../../assests/images/category1.png'

const Categorysection = () => {
    return (
        <>
            <div className="category-section bg-white">
                <Container>
                    {/* Heading Section */}
                    <Row className="align-items-center">
                        <Col xs={12} md={12} className="pt-4">
                            <h2 className="text-darkbrown fs-2">Featured Categories <Badge bg="bg-darkbrown" className='bg-darkbrown blink'>  No Making Charges On Any Products</Badge></h2>
                            <p className="text-darkbrown fw-normal fs-5">
                                Browse our curated collections for every style.
                            </p>
                        </Col>
                        <Col xs={12} md={6} className="pt-4 d-flex justify-content-md-end justify-content-center align-items-center">
                            {/* <Link to="/all-products" className="text-darkbrown fs-4">
                                <span>All categories</span>
                            </Link> */}
                        </Col>
                    </Row>

                    {/* Categories Section */}
                    <Row className="mt-4">
                        <Col xs={12} md={4} className="mb-4">
                            <Link to="/category/home-furnishings" className='text-decoration-none'>
                                <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733518692/products/bt6s69k4rwjzhzgy0abs.png" alt="" className="img-fluid product-img" />
                                <div>
                                    <h3 className="text-darkbrown fs-5 pt-3">Home Furnishings </h3>
                                </div>
                                <hr />
                            </Link>
                        </Col>
                        <Col xs={12} md={4} className="mb-4">
                            <Link to="/category/home-comforts" className='text-decoration-none'>
                                <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733124363/hero%20section%20fakhri/ony3bb6m596ugsy8xhik.png" alt="" className="img-fluid product-img" />
                                <div>
                                    <h3 className="text-darkbrown fs-5 pt-3">Home Comfort</h3>
                                </div>
                                <hr />
                            </Link>
                        </Col>
                        <Col xs={12} md={4} className="mb-4">
                            <Link to="/category/outdoor-utility-products" className='text-decoration-none'>
                                <img src="https://res.cloudinary.com/dj6r4vyd8/image/upload/v1733123934/hero%20section%20fakhri/odfam37nkxjmfvawn4ap.png" alt="" className="img-fluid product-img" />
                                <div>
                                    <h3 className="text-darkbrown fs-5 pt-3">Outdoor and Utility Products</h3>
                                </div>
                                <hr />
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    )
}

export default Categorysection