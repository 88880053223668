import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import aboutuschair from '../../assests/images/sofa.png'
import aboutusimg2 from '../../assests/images/sofa2.png'
import '../../assests/css/aboutus.css'

const Aboutussection = () => {
    return (
        <>
            <div className="aboutus-sec bg-lightestblue">
                <Container>
                    <Row>
                        {/* Left Image */}
                        <Col xs={12} md={6} lg={3} className="p-4 aboutus-col-left">
                            <img src={aboutuschair} alt="" className="img-fluid" />
                        </Col>

                        {/* About Us Content */}
                        <Col xs={12} md={12} lg={6} className="p-4 d-flex flex-column justify-content-center">
                            <h2 className="text-darkbown fw-medium fs-1 text-start">About Us</h2>
                            <p className="aboutus-txt">
                                At Fakhri Traders, we pride ourselves on offering a wide selection of handloom items that meet the highest standards of quality and affordability. With years of experience in the textile and home furnishing industry, we are committed to providing our customers with products that enhance the beauty and comfort of their homes.
                            </p>
                        </Col>

                        {/* Right Image */}
                        <Col xs={12} md={6} lg={3} className="p-4">
                            <img src={aboutusimg2} alt="" className="img-fluid" />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Aboutussection