import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { FaPhoneAlt } from 'react-icons/fa'
import { MdOutlineMail } from 'react-icons/md'
import { SlSocialInstagram } from 'react-icons/sl'

const TopBar = () => {
    return (
        <>
            <div className="top-bar bg-mediumbrown">
                <Container>
                    <Row className="align-items-center">
                        {/* Contact Info */}
                        <Col xs={12} md={6} className="py-2 d-flex flex-wrap justify-content-center justify-content-md-start text-center text-md-left align-items-end">
                            <div>
                                <a href="tel:+919998183251" className="text-white text-decoration-none fs-6 fs-md-5">
                                    <FaPhoneAlt />
                                    <span className="ms-2">+91-9998183251</span>
                                </a>
                            </div>
                            <div className="px-md-5 px-3 mt-2 mt-md-0">
                                <a href="mailto:contact@fakhritraders.com" className="text-white text-decoration-none fs-6 fs-md-5">
                                    <MdOutlineMail />
                                    <span className="ms-2">contact@fakhritraders.com</span>
                                </a>
                            </div>
                        </Col>

                        {/* Social Icons */}
                        <Col xs={12} md={6} className="py-2 d-flex justify-content-center justify-content-md-end text-white">
                            <a  className="text-decoration-none" href="https://www.instagram.com/fakhrii.traders/">
                                <SlSocialInstagram size={'24px'}  className="text-decoration-none" color='white'/>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>


        </>
    )
}

export default TopBar