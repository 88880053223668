import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Col, Container, Nav, Navbar, Row, NavDropdown } from 'react-bootstrap';
import { FaBars, FaCaretDown, FaCaretUp } from 'react-icons/fa'; // Hamburger icon
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'; // Dropdown icons
import { Link as ScrollLink } from 'react-scroll'; // Import react-scroll's Link

const Header = () => {
  const isMobile = useMediaQuery({ maxWidth: 991 }); // Mobile breakpoint
  const [showMenu, setShowMenu] = useState(false); // Toggle for mobile menu
  const [showDropdown, setShowDropdown] = useState(false); // Toggle for mobile dropdown

  return (
    <Navbar data-bs-theme="light" className="bg-white position-relative">
      <Container fluid>
        <Row className="w-100 align-items-center">
          {isMobile ? (
            // Mobile Layout
            <Col xs={12} className="d-flex justify-content-between align-items-center">
              {/* Logo */}
              <Navbar.Brand href="/">
                <img src="../logo.png" alt="Logo" className="img-fluid" style={{ maxHeight: '50px' }} />
              </Navbar.Brand>

              {/* Hamburger Icon */}
              <button
                className="btn text-dark fw-medium fs-4 border-0"
                onClick={() => setShowMenu(!showMenu)}
                aria-label="Toggle Menu"
                style={{
                  zIndex: 1050,
                }}
              >
                <FaBars />
              </button>
            </Col>
          ) : (
            // Desktop Layout
            <>
              <Col lg={4} className="text-start">
                <Navbar.Brand href="/">
                  <img src="../logo.png" alt="Logo" className="img-fluid" />
                </Navbar.Brand>
              </Col>
              <Col lg={6} className="text-center">
                <Nav>
                  <Nav.Link href='/'
                    className="text-dark fw-medium fs-5 me-3 nav-link"
                  >
                    Home
                  </Nav.Link>
                  <ScrollLink
                    to="aboutus-sec"
                    smooth={true}
                    duration={500}
                    offset={-70}
                    style={{ cursor: 'pointer' }}
                    className="text-dark fw-medium fs-5 me-3 nav-link"
                  >
                    About Us
                  </ScrollLink>
                  <NavDropdown
                    title={<span className="text-dark fw-medium fs-5">Products</span>}
                    id="basic-nav-dropdown"
                    className="me-3"
                  >
                    <NavDropdown.Item href="/category/home-furnishings">Home Furnishing</NavDropdown.Item>
                    <NavDropdown.Item href="/category/home-comforts">Home Comforts</NavDropdown.Item>
                    <NavDropdown.Item href="/category/outdoor-utility-products">Outdoor & Utility Products</NavDropdown.Item>
                  </NavDropdown>
                  <ScrollLink
                    to="contact-sec"
                    smooth={true}
                    duration={500}
                    offset={-70}
                    className="text-dark fw-medium fs-5 me-3 nav-link"
                  >
                    Contact Us
                  </ScrollLink>
                </Nav>
              </Col>
            </>
          )}
        </Row>

        {/* Full-Width Mobile Menu */}
        {isMobile && showMenu && (
          <div
            className="position-absolute bg-white shadow p-3"
            style={{
              top: '100%', // Ensures it is below the navbar
              left: 0,
              width: '100%',
              zIndex: 1049,
              transition: '0.3s ease',
            }}
          >
            <Nav className="d-flex flex-column">
              <ScrollLink
                to="home-sec"
                smooth={true}
                duration={500}
                offset={-70}
                className="text-dark fw-medium fs-5 mb-2 nav-link"
                onClick={() => setShowMenu(false)}
              >
                Home
              </ScrollLink>
              <ScrollLink
                to="aboutus-sec"
                smooth={true}
                duration={500}
                offset={-70}
                className="text-dark fw-medium fs-5 mb-2 nav-link"
                onClick={() => setShowMenu(false)}
              >
                About Us
              </ScrollLink>
              <button
                className="btn text-dark fw-medium fs-5 text-start w-100 mb-2 border-0 d-flex align-items-center"
                onClick={() => setShowDropdown(!showDropdown)}
                aria-expanded={showDropdown}
              >
                Products
                {showDropdown ? <FaCaretUp className="ms-2" /> : <FaCaretDown className="ms-2" />}
              </button>

              {showDropdown && (
                <div className="ps-3">
                  <Nav.Link
                    href="/category/home-furnishings"
                    className="text-dark fw-medium fs-6 mb-2"
                    onClick={() => {
                      setShowDropdown(false);
                      setShowMenu(false);
                    }}
                  >
                    Home Furnishing
                  </Nav.Link>
                  <Nav.Link
                    href="/category/home-comforts"
                    className="text-dark fw-medium fs-6 mb-2"
                    onClick={() => {
                      setShowDropdown(false);
                      setShowMenu(false);
                    }}
                  >
                    Home Comforts
                  </Nav.Link>
                  <Nav.Link
                    href="/category/outdoor-utility-products"
                    className="text-dark fw-medium fs-6 mb-2"
                    onClick={() => {
                      setShowDropdown(false);
                      setShowMenu(false);
                    }}
                  >
                    Utility Products
                  </Nav.Link>
                </div>
              )}
              <ScrollLink
                to="contact-sec"
                smooth={true}
                duration={500}
                offset={-70}
                className="text-dark fw-medium fs-5 mb-2 nav-link"
                onClick={() => setShowMenu(false)}
              >
                Contact Us
              </ScrollLink>
            </Nav>
          </div>
        )}
      </Container>
    </Navbar>
  );
};

export default Header;
