import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Section4 = () => {
    return (
        <>
            <div className="footer-sec4">
                <Container>
                    <h4 className='text-darkbrown fw-medium fs-4 pt-3 text-start'>
                        Information
                    </h4>
                    <div className='pt-3'>
                        <p>
                            <span className='fw-bold'>Address:</span> Sadar Bazar, Opp. Ambica Season Store, NuttanPress road, Rajkot.
                        </p>
                    </div>
                    <div className='pt-3'>
                        <p>
                            <a href="mailto:contact@fakhritraders.com" className='text-decoration-none text-dark'>
                                <span className='fw-bold'>Email:</span> contact@fakhritraders.com
                            </a>
                        </p>
                    </div>
                    <div className='pt-3'>
                        <p>
                            <span className='fw-bold'>Phone:</span> <a href="tel:+919998183251" className='text-decoration-none text-dark'> +91 99981 83251</a>  <div className='px-5'><a href="tel:+918320310090" className='text-decoration-none text-dark'>+91 83203 10090
                            </a>
                            </div>
                        </p>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Section4