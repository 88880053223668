import React from 'react'
import { Container } from 'react-bootstrap'

const Copyright = () => {
  return (
    <>
    <div className="copyright text-center p-2">
        <Container>
            <p>Copyright 2020. All rights reserved. <span> Powered By Taher Balasinorwala</span></p>
        </Container>
    </div>
    </>
  )
}

export default Copyright